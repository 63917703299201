import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import { useEffect } from 'react';
function About() {
  useEffect(() => {
    // Ensure Facebook SDK is initialized after the component mounts
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            Tamizhaga Vettri Kazhagam  <strong className="purple">(TVK)</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }} className="about-img">
            <img src={laptopImg} alt="about" className="img-fluid" />
            <br />
            <br />
            <div class="fb-post" data-href="https://www.facebook.com/reel/2510676079138830"></div>
            

          </Col>
        </Row>
       
      </Container>
    </Container>
  );
}

export default About;
