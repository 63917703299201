import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
import Contactcard from "./ContactCard";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

function Contactus() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const [disabled, setDisabled] = useState(false);
      const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
      });
    
      // Shows alert message for form submission feedback
      const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });
    
        // Hide alert after 5 seconds
        setTimeout(() => {
          setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
      };
      // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      // Use emailjs to email contact form data
      await emailjs.send(
        // import.meta.env.VITE_SERVICE_ID,
        // import.meta.env.VITE_TEMPLATE_ID,
        // templateParams,
        // import.meta.env.VITE_PUBLIC_KEY,

        process.env.REACT_APP_VITE_SERVICE_ID,
        process.env.REACT_APP_VITE_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_VITE_PUBLIC_KEY,

        
      );

      // Display success alert
      toggleAlert('Thank you for contacting us. We will get back asap', 'success');
    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert('Oh no, Seems there is an issue. Please try again later', 'danger');
    } finally {
      // Re-enable form submission
      setDisabled(false);
      // Reset contact form fields after submission
      reset();
    }
  };

  return (
    <Container fluid className="about-section">
      {/* <Particle /> */}

      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            Tamizhaga Vettri Kazhagam  <strong className="purple">(TVK)</strong>
            </h1>
            <h2>Send us a Message</h2>
            <p>Please fill the form below to get in touch with us</p><br /><br />
           
            <div className='ContactForm'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='contactForm'>
                        <form
                            id='contact-form'
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                        >
                            {/* Row 1 of form */}
                            <div className='row formRow'>
                            <div className='col-6'>
                                <input
                                type='text'
                                name='name'
                                {...register('name', {
                                    required: {
                                    value: true,
                                    message: 'Please enter your name',
                                    },
                                    maxLength: {
                                    value: 30,
                                    message: 'Please use 30 characters or less',
                                    },
                                })}
                                className='form-control formInput'
                                placeholder='Name'
                                ></input>
                                {errors.name && (
                                <span className='errorMessage'>
                                    {errors.name.message}
                                </span>
                                )}
                            </div> 
                            <div className='col-6'>
                                <input
                                type='email'
                                name='email'
                                {...register('email', {
                                    required: true,
                                    maxLength: {
                                      value: 70,
                                      message: 'Subject cannot exceed 70 characters',
                                      },
                                    pattern:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                })}
                                className='form-control formInput'
                                placeholder='Email address'
                                ></input>
                                
                                {errors.email && (
                                <span className='errorMessage'>
                                    Please enter a valid email address
                                </span>
                                )}
                            </div>
                            </div><br />
                            {/* Row 2 of form */}
                            <div className='row formRow'>
                            <div className='col'>
                                <input
                                type='text'
                                name='subject'
                                {...register('subject', {
                                    required: {
                                    value: true,
                                    message: 'Please enter a subject',
                                    },
                                    maxLength: {
                                    value: 150,
                                    message: 'Subject cannot exceed 150 characters',
                                    },
                                })}
                                className='form-control formInput'
                                placeholder='Subject'
                                ></input>
                                {errors.subject && (
                                <span className='errorMessage'>
                                    {errors.subject.message}
                                </span>
                                )}
                            </div>
                            </div><br />
                            {/* Row 3 of form */}
                            <div className='row formRow'>
                            <div className='col'>
                                <textarea
                                rows={3}
                                name='message'
                                {...register('message', {
                                    required: true,
                                    maxLength: {
                                      value: 250,
                                      message: 'Subject cannot exceed 250 characters',
                                      },
                                })}
                                className='form-control formInput'
                                placeholder='Message'
                                ></textarea>
                                {errors.message && (
                                <span className='errorMessage'>
                                    Please enter a message
                                </span>
                                )}
                            </div>
                            </div><br />

                            <button
                            className='submit-btn btn btn-primary'
                            disabled={disabled}
                            type='submit'
                            >
                            Submit
                            </button>
                        </form><br />
                        </div>
                    </div>
                    </div>
                </div>
                {alertInfo.display && (
                    <div
                    className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
                    role='alert'
                    >
                    {alertInfo.message}
                    <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='alert'
                        aria-label='Close'
                        onClick={() =>
                        setAlertInfo({ display: false, message: '', type: '' })
                        } // Clear the alert when close button is clicked
                    ></button>
                </div>
                )}
            </div>
           
            <Contactcard />
          </Col>
          
        </Row>
       
      </Container>
    </Container>
  );
}

export default Contactus;
