import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "உங்க மகன்..",
          "உங்க அண்ணன்..",
          "உங்க தோழன்..",
          "உங்க விஜய்..",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 40,
      }}
    />
  );
}

export default Type;
