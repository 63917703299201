import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>Tamilaga Vettri Kazhagam (TVK) is a dynamic political party born out of a passionate commitment to social justice, equality, and the empowerment of women. Founded with the vision to transform Tamil Nadu into a progressive, inclusive, and corruption-free state, TVK is driven by the ideals of the Indian Constitution and the desire to uplift every citizen, especially the marginalized and underrepresented.</p>
          <p style={{ color: "#fff" }}><b>"பிறப்பொக்கும் <span className="purple"> எல்லா </span> உயிர்க்கும்!"{" "}</b></p>
          <br /><h3 style={{ textAlign: "justify" }}>Our Vision</h3>
          <p style={{ textAlign: "justify" }}>TVK envisions a Tamil Nadu where all people—regardless of caste, creed, or gender—are given equal opportunities to succeed. We are dedicated to providing a platform for social justice, tackling systemic inequality, and ensuring that the voices of the people are heard, especially those of women and disadvantaged communities.</p>
          <p style={{ textAlign: "justify" }}>As a party, TVK stands firmly against the forces that divide and exploit our state. Our ideological enemies are the divisive powers that perpetuate corruption and inequality—particularly the BJP and the DMK. We are determined to restore integrity, unity, and fairness to Tamil Nadu by challenging these forces and working to rebuild a Tamil Nadu that is governed by the principles of justice and equality.</p>
          <br /><h3 style={{ textAlign: "justify" }}>Our Commitment</h3>
          <p style={{ textAlign: "justify" }}>TVK pledges to create a transformative impact in the 2026 elections. Our core agenda revolves around:</p>
          <br /><h3 style={{ textAlign: "justify" }}>Why TVK?</h3>
          <p style={{ textAlign: "justify" }}>The future of Tamil Nadu requires a political force that is both radical in its vision and practical in its solutions. TVK is not just another political party—TVK is a movement for the common people, for those who have been denied their fair share of power and opportunity for too long.</p>
          <p style={{ textAlign: "justify" }}>With the leadership of Vijay, a symbol of change and empowerment, TVK is prepared to take on the challenges of today and build a brighter tomorrow. We believe that Tamil Nadu deserves a leadership that reflects its people’s hopes, dreams, and aspirations. A leadership that understands the pulse of the people and is committed to action, not just words.</p>
          <br /><h3 style={{ textAlign: "justify" }}>Our Ideology</h3>
          <p style={{ textAlign: "justify" }}>TVK operates on a foundation of equality, justice, and empowerment. We believe in the principles laid out in the Indian Constitution and are resolute in ensuring they are reflected in the policies of Tamil Nadu. The party’s ideological platform is focused on:</p>
          <p style={{ textAlign: "justify" }}></p>
          <br /><h3 style={{ textAlign: "justify" }}>The Road to 2026</h3>
          <p style={{ textAlign: "justify" }}>As we prepare for the 2026 elections, TVK stands as the primary force ready to retrieve Tamil Nadu from the clutches of corrupt and divisive forces. Our promise is clear: we will create a Tamil Nadu where justice and equality are not just ideals, but a lived reality for every citizen. The struggle for a better Tamil Nadu starts now, and we invite you to join us in this historic movement.</p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
