import React from "react";
import Card from "react-bootstrap/Card";

function ContactCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ color: "#fff" }}><b>"பிறப்பொக்கும் <span className="purple"> எல்லா </span> உயிர்க்கும்!"{" "}</b></p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default ContactCard;
