import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { useEffect } from 'react';

function MediaNew() {
  useEffect(() => {
    // Ensure Facebook SDK is initialized after the component mounts
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);
  return (
    <Container fluid className="media-section">
      <Particle />
      <Container>
        <h1 className="media-heading">
          {/* Our <strong className="purple"> Leaders </strong> */}
        </h1>
        <p style={{ color: "white" }}>
        Stay informed and connected with TVK’s latest initiatives, news, and events. As the voice of the Tamil people, TVK is committed to transparency, community engagement, and promoting the rights and heritage of the Tamil community. Here, you’ll find our press releases, event highlights, media galleries, and coverage from leading news sources. Explore and join us as we work toward a brighter future for Tamil Nadu and beyond.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <h3 style={{textAlign: "justify", padding:"40px 0 0 25px ", color:"#fff"}}>October 27, 2024 Conference's highlights in V.Salai, Vikravandi</h3>
          
          <Col md={4} className="media-card">
          <div class="fb-post" data-href="https://www.facebook.com/reel/2510676079138830"></div>
          </Col>

          <Col md={6} className="media-card">
          <div class="fb-post" data-href="https://fb.watch/vVKBD-jUb9/"></div>
          </Col>
          
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="media-card">
          <div class="fb-post" data-href="https://www.facebook.com/reel/1074842287652835"></div>
          </Col>

          <Col md={4} className="media-card">
          <div class="fb-post" data-href="https://www.facebook.com/reel/2111296399284933"></div>
          </Col>

        </Row>

        {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <h3 style={{textAlign: "justify", padding:"20px 0 0 25px ", marginBottom:"-25px", color:"#fff"}}>Photos & Videos</h3>
          <Col md={2} className="media-card">
            <MediaCard
              imgPath={chatify}
              isBlog={false}
              title=""
              description=""/>
          </Col>

          <Col md={2} className="media-card">
            <MediaCard
              imgPath={editor}
              isBlog={false}
              title=""
              description=""/>
          </Col>

          <Col md={2} className="media-card">
            <MediaCard
              imgPath={leaf}
              isBlog={false}
              title=" "
              description=""/>
          </Col>

          <Col md={2} className="media-card">
            <MediaCard
              postUrl="https://www.facebook.com/reel/2510676079138830"
              imgPath={suicide}
              isBlog={false}
              title=""
              description="Overwhelming of Love and Support" />
          </Col>

          <Col md={2} className="media-card">
            <MediaCard
              postUrl="https://www.facebook.com/reel/2510676079138830"
              imgPath={suicide}
              isBlog={false}
              title=""
              description="Overwhelming of Love and Support" />
          </Col>

        </Row> */}


      </Container>
    </Container>
  );
}

export default MediaNew;
