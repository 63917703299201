import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/Kamaraj.png";
import emotion from "../../Assets/Projects/periyaar.png";
import editor from "../../Assets/Projects/anjalai-ammal.png";
import chatify from "../../Assets/Projects/ambethkar.png";
import suicide from "../../Assets/Projects/velu-natchiyaar.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple"> Leaders </strong>
        </h1>
        <p style={{ color: "white" }}>
        Structured list of key leaders of the Tamizhaga Vettri Kazhagam (TVK) party
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Dr. B.R. Ambedkar"
              description="An architect of the Indian Constitution, Ambedkar was a champion of equality and social reform."
              
            />
          </Col>

         

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Anjalai Ammal"
              description="A lesser-known but respected figure, Anjalai Ammal contributed significantly to social causes and Tamil literature."
                           
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="K. Kamaraj "
              description="A prominent freedom fighter and former Chief Minister of Tamil Nadu, Kamaraj focused on education and development."
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Velu Nachiyar"
              description="Known as the first queen to fight against British colonialism, Velu Nachiyar exemplifies bravery and leadership."
              />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Periyar E.V. Ramasamy"
              description="A social activist known for promoting rationalism, self-respect, and social justice, Periyar advocated for the rights of Tamil people and marginalized groups."
              />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
