import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.png";
import Particle from "../Particle";
import Type from "./Type";
import myImg from "../../Assets/tvk-flag.png";
import Tilt from "react-parallax-tilt";
import Type2 from "./Type2";
import {
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";



function Home() {
  return (
    <section>
      <Container fluid className="about-section">
        <Particle />
        <Container className="home-content">
        <div style={{ padding: 0, textAlign: "left" }}>
                <Type2 />
              </div>
          <Row>
            <Col md={5} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
              வணக்கம்!{" "}
                <span className="welcome" role="img" aria-labelledby="welcome">
                🙏
                </span>
              </h1>

              <h1 className="heading-name">
              இது நம் 
                <strong className="main-name"> தமிழக <span style={{color:"#fecc00"}}>வெற்றிக்</span> கழகம் </strong>
              </h1>
            </Col>

            <Col md={7} style={{ paddingLeft: 50 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ Height: "1050px" }}
              />
            </Col>
          </Row>
        </Container>


        <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            பிறப்பொக்கும் <span className="purple"> எல்லா </span> உயிர்க்கும்!
            </h1>
            <h2>Empowering Women, Advancing Justice, Restoring Integrity.</h2>
            <p className="home-about-body">
            At TVK, we are committed to social justice, women’s empowerment, and building a future where equality thrives. Our vision for Tamil Nadu is one of transformative change—where the ideals enshrined in the Indian Constitution are upheld, and where corruption and inequality are confronted head-on.
              {/* <i>
                <b className="purple"> Our ideological enemies are the divisive forces that are damaging this land.  </b>
              </i> */}
              <br />
              <br />
              In the 2026 elections, TVK promises to be the driving force in reclaiming our state from divisive forces. We will stand as the primary power to fight against the forces that undermine our unity and prosperity, and work tirelessly to restore justice and fairness to every citizen.
              <br />
              <br />
              Together, we will create a Tamil Nadu that reflects our shared values of 
              <i><b className="purple"> equality, integrity, and opportunity for all.</b></i>
              <br />
              <br />
              
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
          
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
           
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61568597873787"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61568597873787"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
      </Container>
    </section>
  );
}

export default Home;
